import React from "react";
import Layout from "../../components/layout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { graphql } from 'gatsby';
import Img from "gatsby-image"
const isoCoat = ({data}) => (
    <Layout>
        <Row>
            <Col xs={12} md={8}>
            <h1>Isocoat Product Details</h1>
            <Table>
                <tbody>
                    <tr>
                        <td>Coverage:</td>
                        <td>1 kg/sq. mtr. To obtain 0.8 mm to 1.0 mm dry film thickness. It is dependent on the surface condition and porosity. Apply two coats of 1 kg / sq.mtr.
</td>
                    </tr>
                    <tr>
                        <td>Packaging:</td>
                        <td>1 ltr, 5ltr, 10ltr and 20ltr
</td>
                    </tr>
                    <tr>
                        <td>Colours:</td>
                        <td>Reflective White and Grey
</td>
                    </tr>
                    <tr>
                        <td>Uses:</td>
                        <td>Suitable for application to most types of roofs, (eg. Flat or sloped) and most materials like concrete, metal, asbestos, wood etc. It has a very good adhesion with concrete surfaces and forms a heat reflecting membrane. It can also be used over GI Sheeting.
</td>
                    </tr>
                </tbody>
            </Table>
            </Col>
            <Col xs={12} md={4}>
            <Img fluid={data.isocoat.childImageSharp.fluid}></Img>
            </Col>
        </Row>
    </Layout>
)

export default isoCoat;
export const query = graphql`
query {
    isocoat:file(relativePath:{eq:"isocoat.jpg"}){
        childImageSharp {
         fluid(maxWidth: 300) {
           ...GatsbyImageSharpFluid
         }
       }
     }
  }
`